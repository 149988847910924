import React, { memo, useMemo } from 'react';
import type { PreloadedEntryPoint } from 'react-relay';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import GlobalIssueNavigatorApp, {
	type Props as GlobalIssueNavigatorProps,
} from '@atlassian/jira-global-issue-navigator-nin/src/index.tsx';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { EnableNinChangeboardingProvider } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import type { View } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { NINPageLoad } from '@atlassian/jira-issue-navigator/src/common/utils/performance-analytics.tsx';
import {
	getJqlFromParamValues,
	getSearchInput,
	useUpdateUrlQueryParamCallback,
} from '@atlassian/jira-issue-navigator/src/common/utils/spa-utils.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	appBundleReadyMark,
	prefetchApiReadyMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { getDefaultJqlGin as getDefaultJql } from '@atlassian/jira-router-resources-issue-navigator/src/controllers/get-data/utils.tsx';
import {
	issueNavigatorFilterRelayResource,
	issueNavigatorRelayResource,
	issueNavigatorHydrationRelayResource,
} from '@atlassian/jira-router-resources-issue-navigator/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';

export type PreloadedEntryPoints = {
	nativeIssueTableEntryPoint?: PreloadedEntryPoint<AnyEntryPoint>;
};

type CommonProps = Omit<
	GlobalIssueNavigatorProps,
	'defaultJql' | 'queryReference' | 'filterQueryReference' | 'searchInput'
>;

export type Props = CommonProps & {
	jql: string | undefined;
	initialised: boolean;
	view?: View;
	filter: string | undefined;
	preloadedEntryPoints?: PreloadedEntryPoints;
};

const AsyncGlobalIssueNavigatorWrapper = ({
	filter,
	jql: jqlParam,
	initialised,
	view,
	...props
}: Props) => {
	const onMutateJqlParam = useUpdateUrlQueryParamCallback('jql');

	const defaultJql = getDefaultJql();

	const jql = getJqlFromParamValues(defaultJql, jqlParam, filter, undefined, onMutateJqlParam);

	const searchInput = useMemo(() => getSearchInput(jql, filter), [jql, filter]);
	const filterForAnalytics = filter && isFilterId(filter) ? filter : 'default';
	const attributes = {
		navigatorScope: 'global',
		viewLayout: view || '',
		// jql='' is a valid jql parameter, so we track empty jql
		jql: jqlParam !== undefined,
		filter: filterForAnalytics,
	};

	const extraInfo = {
		scope: 'global',
		filterId: filterForAnalytics,
		isJqlParamPresent: !!jqlParam,
		...attributes,
	};

	const response = useRelayResource(issueNavigatorRelayResource);
	const { queryReference } = response;

	const filterResponse = useRelayResource(issueNavigatorFilterRelayResource);
	const filterQueryReference = filterResponse.queryReference;

	const hydrationResponse = useRelayResource(issueNavigatorHydrationRelayResource);
	const hydrationQueryReference = hydrationResponse.queryReference;

	const issueTableProps = useMemo(
		() =>
			fg('jira_list_gin_entrypoint')
				? {
						preloadedEntryPoints: props.preloadedEntryPoints,
					}
				: undefined,
		[props.preloadedEntryPoints],
	);

	// During SSR our issueNavigatorRelayResource must wait until the query finishes before returning the
	// queryReference as SSR does not support Suspense. We return the page skeleton as an intermediary state until
	// the query has resolved (or SSR times out and the query is loaded on the client).
	if (queryReference == null) {
		return <GlobalIssueNavigatorSkeleton />;
	}

	// During SSR our issueNavigatorFilterRelayResource must wait until the query finishes before returning the
	// queryReference as SSR does not support Suspense. We return the page skeleton as an intermediary state until
	// the query has resolved (or SSR times out and the query is loaded on the client).
	if (filterQueryReference == null) {
		return <GlobalIssueNavigatorSkeleton />;
	}

	return (
		<ContextualAnalyticsData
			attributes={attributes}
			{...(filter !== null &&
				filter !== undefined &&
				isFilterId(filter) && {
					containers: { issueFilter: { id: filter } },
				})}
		>
			<ReportErrors
				id="unhandled"
				packageName="jiraSpaAppsGlobalIssueNavigator"
				teamName="empanada"
				sendToPrivacyUnsafeSplunk
				attributes={isLoaderErrorAttributes}
			>
				{/* Render a Suspense boundary to handle the scenario where the resource query times out in SSR and
                content (i.e. nav) is still returned. Ideally we'd use the SPA level suspense boundary but there is some
                internal state logic in RLL that is preventing the fallback being rendered after the async component has
                successfully imported. */}
				<Placeholder name="global-issue-navigator-app" fallback={<GlobalIssueNavigatorSkeleton />}>
					<EnableNinChangeboardingProvider>
						<GlobalIssueNavigatorApp
							{...props}
							searchInput={searchInput}
							defaultJql={defaultJql}
							queryReference={queryReference || undefined}
							filterQueryReference={filterQueryReference || undefined}
							jqlBuilderProps={{
								maybeHydrationQueryReference: hydrationQueryReference || undefined,
							}}
							issueTableProps={issueTableProps}
						/>
					</EnableNinChangeboardingProvider>
				</Placeholder>
			</ReportErrors>

			{initialised && (
				<>
					{/* @ts-expect-error - Types for JFE BM3Metric and @atlassian/browser-metrics' PageLoadMetric are not compatible */}
					<SubmitApdex
						appName="global-issue-navigator"
						isExpectedToHaveSsr
						withMarks={[appBundleReadyMark, prefetchApiReadyMark]}
						ttrSlo={3000}
						ttiSlo={4000}
						extra={extraInfo}
						metric={NINPageLoad}
						emitOnAnimationFrame
					/>
					<SpaStatePageReady />
				</>
			)}
			<SubProductUpdater subProduct="platform" />
		</ContextualAnalyticsData>
	);
};

export default memo<Props>(AsyncGlobalIssueNavigatorWrapper);
