// eslint-disable-next-line jira/restricted/react-component-props
import React, { useLayoutEffect, type ReactElement, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import ExportIssues from '@atlassian/jira-issue-navigator-action-export-issues/src/ui/async.tsx';
import MeatballMenu from '@atlassian/jira-issue-navigator-action-meatball-menu/src/ui/index.tsx';
import PluggableItems from '@atlassian/jira-issue-navigator-action-pluggable-items/src/ui/async.tsx';
import { ShareButton } from '@atlassian/jira-issue-navigator-action-share-button/src/ui/index.tsx';
import type { SearchResultPageData } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import {
	markOnce,
	marks,
} from '@atlassian/jira-issue-navigator/src/common/utils/performance-analytics.tsx';
import ViewSwitcher from '@atlassian/jira-issue-navigator/src/ui/view-switcher/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type PageActionProps = {
	jql: string;
	filterId?: string;
	isModifiedFilter?: boolean;
	searchResultPageData: SearchResultPageData;
	filterName?: string;
};

const PageActions = ({
	jql,
	filterId,
	isModifiedFilter,
	searchResultPageData,
	filterName,
}: PageActionProps): ReactElement<ComponentProps<typeof ActionsContainer>> => {
	markOnce(marks.PAGE_ACTIONS_START);
	useLayoutEffect(() => {
		markOnce(marks.PAGE_ACTIONS_END);
	}, []);

	const isAnonymous = useIsAnonymous();

	return (
		<ActionsContainer>
			<StyledPageAction>
				<PluggableItems />
			</StyledPageAction>
			{!isAnonymous && (
				<StyledPageAction>
					<ShareButton jql={jql} filterId={filterId} isModifiedFilter={isModifiedFilter} />
				</StyledPageAction>
			)}
			{!(isVisualRefreshEnabled() && fg('visual-refresh-headers-buttons')) && (
				<StyledPageAction>
					<ExportIssues
						jql={jql}
						filterId={filterId}
						isModifiedFilter={isModifiedFilter}
						showEcosystemApps
						filterName={filterName}
					/>
				</StyledPageAction>
			)}
			<StyledPageAction>
				<ViewSwitcher filterId={filterId} />
			</StyledPageAction>
			<StyledPageAction>
				<MeatballMenu jql={jql} filterId={filterId} searchResultPageData={searchResultPageData} />
			</StyledPageAction>
		</ActionsContainer>
	);
};

export default PageActions;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPageAction = styled.div({
	marginTop: 0,
	marginRight: token('space.050'),
	marginBottom: 0,
	marginLeft: token('space.050'),
	minWidth: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
});
