import React, { type ComponentType, Component, memo } from 'react';
import '@atlaskit/css-reset';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import type { EntryPointProps } from 'react-relay';
import { parse as fromQueryString, stringify as toQueryString } from 'query-string';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { metrics } from '@atlassian/browser-metrics';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { SmartQueryDetectedFlag } from '@atlassian/jira-issue-navigator-flags/src/ui/smart-query-detected-flag/index.tsx';
import { DEFAULT_VIEW_ID } from '@atlassian/jira-issue-navigator/src/common/constants.tsx';
import type { IssueNavigatorViewId } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { convertToView } from '@atlassian/jira-issue-navigator/src/common/utils/index.tsx';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	type Location,
	type Match,
	type RouterActionPush as Push,
	type RouterActionReplace as Replace,
	RouterActions,
	RouterSubscriber,
} from '@atlassian/react-resource-router';
import App, { type PreloadedEntryPoints } from './ui/index.tsx';

type GlobalIssueNavigatorSPAWrapperProps = {
	push: Push;
	replace: Replace;
	location: Location;
	match: Match | null | undefined;
	preloadedEntryPoints?: PreloadedEntryPoints;
};

type GlobalIssueNavigatorSPAWrapperState = {
	initialised: boolean;
	issueKey: string;
};

type MutableSearchParams = {
	issueKey: IssueKey;
	filter?: string;
	jql?: string;
	smartQueryDisabled?: string;
	viewActivity?: boolean;
};

type InteractionProperties = {
	filterId?: string;
	view?: string;
};

const BROWSER_METRICS_INTERACTION_APP_KEY = 'jira.global-issue-navigator';

const initInteractions = () => ({
	pagination: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.pagination.start-end.diff`,
		}),
		properties: {},
	},
	refinement: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.refinement.start-end.diff`,
		}),
		properties: {},
	},
	filter: {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.filter.start-end.diff`,
		}),
		properties: {},
	},
	'list-view': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.list-view.start-end.diff`,
		}),
		properties: {},
	},
	'split-view': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.split-view.start-end.diff`,
		}),
		properties: {},
	},
	'column-configuration': {
		metrics: metrics.interaction({
			key: `${BROWSER_METRICS_INTERACTION_APP_KEY}.column-configuration.start-end.diff`,
		}),
		properties: {},
	},
});
type Interactions = ReturnType<typeof initInteractions>;
type InteractionKey = keyof Interactions;

// eslint-disable-next-line jira/react/no-class-components
class GlobalIssueNavigatorSPAWrapper extends Component<
	GlobalIssueNavigatorSPAWrapperProps,
	GlobalIssueNavigatorSPAWrapperState
> {
	constructor(props: GlobalIssueNavigatorSPAWrapperProps) {
		super(props);

		const { location } = props;
		this.locationSearchStr = location.search;
		this.activeInteractions = [];
		this.view = DEFAULT_VIEW_ID;
		this.interactions = initInteractions();
	}

	state = {
		initialised: false,
		issueKey: this.props.match?.params.issueKey ?? '',
	};

	componentDidUpdate(prevProps: GlobalIssueNavigatorSPAWrapperProps) {
		const prevIssueKey = prevProps.match?.params.issueKey ?? '';
		const issueKey = this.props.match?.params.issueKey ?? '';
		// If issue key has changed from the router and does not match our internal key then update our state
		if (issueKey !== prevIssueKey && issueKey !== this.state.issueKey) {
			this.setState({
				issueKey,
			});
		}
	}

	interactions: Interactions;

	activeInteractions: InteractionKey[];

	view: IssueNavigatorViewId;

	lastFilter: string | undefined;

	/**
	 * Update the currently selected issue in the URL.
	 *
	 * @param issueKey Issue key to update in the URL
	 * @param isSelectedByUserInteraction True if this issue was selected by a user interaction, e.g. clicking an issue
	 * in the list. This will be false if an issue was automatically selected by the app, e.g. automatically selecting
	 * the first issue once the issue list has loaded.
	 * @param viewActivity True to indicate the issue app should scroll to the activity feed section after opening.
	 */
	onChangeIssue = (
		issueKey: IssueKey,
		isSelectedByUserInteraction: boolean,
		viewActivity?: boolean,
	) => {
		// Replace the history entry when an issue is auto selected by the app so we're not pushing redundant entries to
		// the history stack
		this.push(
			{
				issueKey,
				...(fg('jsc_inline_editing_field_refactor') ? { viewActivity } : {}),
			},
			!isSelectedByUserInteraction,
		);
	};

	onChangePage = () => {
		this.startInteraction('pagination', { view: this.view });
		fg('add_nin_press_interactions') && traceUFOPress(`issue-navigator.pagination.${this.view}`);
	};

	onSetView = (newView: IssueNavigatorViewId, issueKey?: IssueKey) => {
		this.startInteraction(convertToView(newView));
		fg('add_nin_press_interactions') && traceUFOPress(`issue-navigator.${convertToView(newView)}`);
		this.view = newView;
		if (issueKey !== undefined) {
			this.push({ issueKey }, true);
		}
	};

	onRefinement = () => {
		this.startInteraction('refinement');
		fg('add_nin_press_interactions') && traceUFOPress('issue-navigator.refinement');
	};

	/**
	 * Callback function called when internal changes are made to PIN's state which generates new JQL.
	 *
	 * @param jql string The new JQL string
	 * @param clearFilter boolean When true we clear the filter from url params
	 */
	onChangeJql = (jql: string, clearFilter = false) => {
		this.push({
			...(clearFilter ? { filter: undefined } : {}),
			jql,
			// Clear issue key from URL whenever JQL changes
			issueKey: toIssueKey(''),
		});
	};

	onPageDataLoad = (selectedView: IssueNavigatorViewId) => {
		if (selectedView !== undefined) {
			this.view = selectedView;
		}
		if (!this.state.initialised) {
			this.setState({
				initialised: true,
			});
		}
		this.endInteractions();
	};

	onStartFilterChangeInteractionIfAny = (filter?: string) => {
		if (this.lastFilter !== filter) {
			this.lastFilter = filter;

			if (filter != null && isFilterId(filter)) {
				this.startInteraction('filter', { filterId: filter });
				fg('add_nin_press_interactions') && traceUFOPress('issue-navigator.filter');
			}
		}
	};

	onChangeColumnConfiguration = () => {
		this.startInteraction('column-configuration');
		fg('add_nin_press_interactions') && traceUFOPress('issue-navigator.column-configuration');
	};

	onChangeFilter = (filter?: string) => {
		this.push({ ...(filter ? { filter, jql: undefined } : { jql: undefined }) }, true);
	};

	push(newParams: Partial<MutableSearchParams>, shouldReplace = false) {
		const { push, location, replace } = this.props;
		const historyAction = shouldReplace ? replace : push;

		const oldIssueKey = this.state.issueKey;

		const { issueKey = oldIssueKey ?? '', ...params } = newParams;

		const issueKeyChanged = newParams.issueKey !== oldIssueKey;
		// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
		const query = fromQueryString(location.search, { arrayFormat: 'comma' });

		const queryParamsChanged =
			Object.keys(params).length !== 0 &&
			!isEqual(
				pickBy(params, (param) => param),
				query,
			);

		if (issueKeyChanged || queryParamsChanged) {
			// @ts-expect-error - React-resource-router's external type interface claims push/replace only accept "string", however the underlying code works with the `Location` object we're using
			historyAction({
				pathname: `/issues/${issueKey ?? ''}`,
				// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
				search: toQueryString({ ...query, ...params }, { arrayFormat: 'comma' }),
			});
		}

		if (issueKeyChanged) {
			// We explicitly set this into state instead of being purely prop driven as browser history actions are not
			// re-rendered synchronously. Setting into state makes our render order more reliable.
			this.setState({
				issueKey: issueKey ?? '',
			});
		}

		return true;
	}

	startInteraction = (key: InteractionKey, properties?: InteractionProperties) => {
		if (!this.activeInteractions.includes(key)) {
			this.activeInteractions.push(key);
		}
		const interaction = this.interactions[key];

		if (interaction) {
			interaction.properties = properties || {};
			interaction.metrics.start();
		}
	};

	endInteractions = () => {
		this.activeInteractions.forEach((key) => {
			this.interactions[key]?.metrics.stop({
				customData: this.interactions[key]?.properties,
			});
		});
		this.activeInteractions = [];
	};

	locationSearchStr: string;

	getQueryStringParams = () => {
		const { location } = this.props;
		const { filter, jql } = fromQueryString(location.search, {
			// @ts-expect-error - The "comma" option requires query-string v6.4.0 or newer. JFE currently uses v5.1.1.
			arrayFormat: 'comma',
		});

		return { filter, jql };
	};

	render() {
		const { filter, jql } = this.getQueryStringParams();

		this.onStartFilterChangeInteractionIfAny(filter);

		return (
			<>
				<App
					issueKey={toIssueKey(this.state.issueKey)}
					jql={jql}
					onChangeIssue={this.onChangeIssue}
					onChangePage={this.onChangePage}
					onPageDataLoad={this.onPageDataLoad}
					onChangeColumnConfiguration={this.onChangeColumnConfiguration}
					onChangeJql={this.onChangeJql}
					onChangeFilter={this.onChangeFilter}
					initialised={this.state.initialised}
					onRefinement={this.onRefinement}
					onSetView={this.onSetView}
					view={convertToView(this.view)}
					filter={filter}
					preloadedEntryPoints={this.props.preloadedEntryPoints}
				/>
				{this.state.initialised && (
					<JSErrorBoundary
						id="smartQueryDetectedFlag"
						packageName="jiraSpaAppsGlobalIssueNavigator"
						teamName="empanada"
						fallback="unmount"
					>
						<SmartQueryDetectedFlag jql={jql} />
					</JSErrorBoundary>
				)}
			</>
		);
	}
}

/**
 * @deprecated delete when jira_list_gin_entrypoint is cleaned up
 */
const GlobalIssueNavigatorSPAWrapperRouterConsumer = () => (
	<UFOSegment name="global-issue-navigator">
		<RouterActions>
			{({ push, replace }) => (
				<RouterSubscriber>
					{({ location, match }) => (
						<GlobalIssueNavigatorSPAWrapper
							push={push}
							location={location}
							match={match}
							replace={replace}
						/>
					)}
				</RouterSubscriber>
			)}
		</RouterActions>
	</UFOSegment>
);

type EntryPointRuntimeProps = {
	preloadedEntryPoints?: PreloadedEntryPoints;
};

export const GlobalIssueNavigatorSPAEntryPoint: ComponentType<
	EntryPointProps<{}, {}, EntryPointRuntimeProps, {}>
> = memo(({ props }) => (
	<UFOSegment name="global-issue-navigator">
		<RouterActions>
			{({ push, replace }) => (
				<RouterSubscriber>
					{({ location, match }) => (
						<GlobalIssueNavigatorSPAWrapper
							push={push}
							location={location}
							match={match}
							replace={replace}
							preloadedEntryPoints={
								fg('jira_list_gin_entrypoint') ? props.preloadedEntryPoints : undefined
							}
						/>
					)}
				</RouterSubscriber>
			)}
		</RouterActions>
	</UFOSegment>
));

export default memo<GlobalIssueNavigatorSPAWrapperProps>(
	GlobalIssueNavigatorSPAWrapperRouterConsumer,
);
